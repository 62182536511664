.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #f1f1f1;
  text-transform: uppercase;

  .hero-content {
    margin-left: 50px;

    h1 {
      font-size: 5em;
      color: #333;
      margin-bottom: 25px;
    }

    h2 {
      font-size: 3em;
      text-wrap: nowrap;
    }

    h2:last-of-type {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1450px) {
    .hero-content h2 {
      white-space: normal;
    }
  }

  .hero-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    .hero-content, .hero-image {
      max-width: 100%;
      text-align: center;
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 30px;

    .hero-content h1 {
      font-size: 2em;
    }

    .hero-content h2 {
      font-size: 1.25em;
    }

    .hero-image img {
      width: 100%;
    }
  }
}

// Модификаторы текста
.blue-accent {
  color: #2e7de5;
}

.uppercase {
  text-transform: uppercase;
}

h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

// Секция 2
.how-it-works {
  margin: 100px 0 180px;

  @media (max-width: 1024px) {
    margin: 100px 0;
  }
}

.adv-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.how-it-works h2 {
  color: #333;
  text-transform: uppercase;
}

.how-it-works p {
  font-size: 1.25em;
  line-height: 1.6;
  color: #333;
}

// Секция 3 "Реклама в телеграм"
.effect-section {
  position: relative;
  margin: 100px 0;

  .adv-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }

  .info-content {
    max-width: 60%;

    h2:last-of-type {
      margin-bottom: 40px;
    }

    h3 {
      font-size: 1.5em;
      margin-bottom: 30px;
    }

    .info-blocks {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      text-wrap: balance;

      .info-block {
        h3 {
          font-size: 1.5em;
          margin-bottom: 10px;
        }

        p {
          font-size: 1.25em;
        }
      }
    }
  }

  .info-image {
    max-width: 40%;
    position: relative;
    top: -80px;
    z-index: -1;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  @media (max-width: 1024px) {
    .adv-container {
      flex-direction: column;
    }

    .info-content {
      max-width: 100%;
      text-align: center;

      .info-blocks {
        grid-template-columns: 1fr;
      }
    }

    .info-image {
      max-width: 80%;
      display: flex;
      justify-content: center;
      position: static;
      margin: 20px auto;

      img {
        width: 80%;
        height: auto;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 30px 0;

    .info-image {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// Секция 4 "Форматы рекламы"
.formats-section {
  margin: 100px 0;

  .formats-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1024px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .formats-image-wrapper {
    max-width: 40%;
    display: flex;
    justify-content: center;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      margin: 0 auto 20px;
    }

    img {
      width: 55%;
      height: auto;
      border-radius: 10px;
    }
  }

  .formats-text {
    max-width: 60%;

    h2:last-of-type {
      margin-bottom: 40px;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
    }

    .formats-title {
      font-size: 2em;
      margin-bottom: 20px;
    }

    .formats-buttons {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      @media (max-width: 768px) {
        justify-content: center;
      }
    }

    .formats-description {
      font-size: 1.25em;
      line-height: 1.5;
      text-align: justify;
    }
  }
}

@media (max-width: 1024px) {
  h2 {
    font-size: 1.5em;
  }

  .formats-image-wrapper img {
    width: 60%;
  }

  .formats-buttons {
    gap: 5px;
    justify-content: center;
  }
}

.carousel-btn {
  background-color: #f1f1f1;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  font-size: 1.2em;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover, &.active {
    background-color: #0098DA;
    color: #fff;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    text-align: center;
  }
}

// Секция 5 "Запуск рекламы"
.launch-ad-section {
  margin: 100px 0;

  .launch-ad-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1024px) {
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
  }

  .launch-ad-text {
    max-width: 50%;

    h2:last-of-type {
      margin-bottom: 40px;
    }

    .custom-list {
      list-style-type: none;
      padding-left: 0;

      li {
        display: flex;
        align-items: center;
        font-size: 1.2em;
        margin-bottom: 20px;
        text-align: left;
        
        img {
          margin-right: 15px;
          width: 24px;
          height: 24px;
        }
      }
    }

    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  .launch-ad-image {
    max-width: 50%;
    
    @media (max-width: 1024px) {
      max-width: 100%;
      margin-top: 20px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

// Секция 6 "Готовы начать"
.get-started-section {
  margin: 100px 0 80px;

  .get-started-header {
    margin-bottom: 40px;
  }

  .get-started-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    height: 520px;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      text-align: center;
      align-items: center;
      height: auto;
      justify-content: flex-end;
    }
  }

  .get-started-buttons {
    display: flex;
    flex-direction: column;
    max-width: 40%;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  .get-started-info {
    max-width: 50%;
    text-align: center;

    .get-started-step {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: column;

      img {
        margin-bottom: 20px;
      }
    }

    .get-started-text {
      p {
        font-size: 1.1em;
      }
    }

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
}

.step-3 img {
  width: 30%;
}

@media (max-width: 1024px) {
  .step-3 img {
    width: 30%;
  }
}

// Секция 7 "Свяжитесь с нами"
.feedback-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.feedback-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.feedback-image {
  max-width: 50%;
}

.feedback-image img {
  width: 100%;
  border-radius: 10px;
}

.feedback-form {
  max-width: 50%;
}

.feedback-form p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.feedback-form form {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .feedback-content {
    flex-direction: column-reverse;
    text-align: center;
  }

  .feedback-image {
    max-width: 100%;
    margin: 60px 0 0;
  }

  .feedback-form {
    max-width: 100%;
    margin: 60px 0 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
}
