.breadcrumbs{
  padding: 32px 0 16px;
  margin-top: 80px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: .4px;
  color: #96A1A7;
  a{
    color: #96A1A7;
    &:hover{color: #0088CC;}
  }
  span{color: #000;}
  @media only screen and (max-width : 767px){
    margin-top: 72px;
  }
}