.benefits {
  margin-bottom: 80px;

  &__content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    gap: 20px;
  }

  &__image {
    flex: 1;
    position: relative;
  }

  &__info-block {
    position: absolute;
    bottom: 40px;
    left: 180px;
    width: 514px;
    height: 136px;
    background-color: #0098DA;
    border-radius: 20px;
    padding: 25px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;

    .icon {
      width: 80px;
      height: 80px;
    }

    .block-title {
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.7);
      margin: 0;
    }
  }

  &__info {
    flex: 1;
  }

  &__title {
    font-size: 40px;
    margin-bottom: 60px;
    white-space: nowrap;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 15px;
    width: 50%;
    margin-bottom: 20px;
    padding: 20px 15px;
  }

  &__icon {
    width: 50px;
    height: 50px;
  }

  &__text {
    h3 {
      font-size: 24px;
      font-weight: 800;
    }

    p {
      font-size: 18px;
      color: #36353B;
      opacity: 0.9;
    }
  }
}

.mobile {
  display: none;
}

@media only screen and (max-width: 1440px) {
  .benefits__image img {
    height: 696px;
    width: 600px;
  }

  .benefits__info-block {
    bottom: 50px;
    left: 100px;
  }
}

@media only screen and (max-width: 1336px) {
  .benefits__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: stretch;
    position: relative;
  }

  .benefits__info {
    display: flex;
    flex-basis: 60%;
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  .benefits__info-block {
    position: static;
    width: 100%;
  }

  .benefits__image {
    position: relative; 
    flex-basis: 40%;
    margin-right: -50px;
  }

  .benefits__image img {
    content: url('../img/pic/adgram-preview-mobile.png');
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }

  .benefits__list {
    margin-bottom: 30px;
}

  .benefits__title {
    font-size: 25px;
    margin-bottom: 30px;
  }

  .benefits__item {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    padding: 10px 5px;
  }

  .benefits__text p {
    font-size: 18px;
    font-weight: 700;
  }

  .benefits__info-block .icon {
    width: auto;
    height: 40px;
  }
}

@media only screen and (max-width: 1127px) {
  .benefits__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: stretch;
    position: relative;
  }

  .benefits__info {
    display: flex;
    flex-basis: 70%;
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  .benefits__info-block {
    position: static;
    width: 100%;
  }

  .benefits__image {
    position: relative; 
    flex-basis: 30%;
  }

  .benefits__image img {
    content: url('../img/pic/adgram-preview-mobile.png');
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }

  .benefits__list {
    margin-bottom: 30px;
  }

  .benefits__title {
    font-size: 25px;
    margin-bottom: 30px;
  }

  .benefits__item {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    padding: 10px 5px;
  }

  .benefits__text h3 {
    font-size: 18px;
  }

  .benefits__text p {
    font-size: 16px;
    font-weight: 400;
  }

  .benefits__info-block .icon {
    width: auto;
    height: 30px;
  }

  .benefits__info-block .block-title {
    font-size: 14px;
  }
}


@media only screen and (max-width: 767px) {

  .mobile {
    display: flex;
  }
  
  .desktop {
    display: none;
  }
  
  .benefits__content {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }

  .benefits__image {
    margin-right: 0;
  }
  
  .benefits__text h3 {
    font-size: 16px;
    font-weight: 800;
  }

  .benefits__text p {
    font-size: 16px;
    font-weight: 400;
  }

  .benefits__info-block {
    text-align: center;
    padding: 15px;
    height: auto;
  }

  .benefits__title {
    margin-bottom: 0;
  }

  .benefits__info {
    padding-left: 0;
    margin-bottom: 0;
  }

  .benefits__info-block .block-title {
    font-size: 12px;
  }

  .benefits__item {
    width: 100%;
    flex-direction: row;
  }

  .benefits__title {
    text-wrap: wrap;
    font-size: 20px;
  }

  .benefits__list {
    margin-bottom: 10px;
  }
}