.services{
  margin: 96px 0;
  &__title{
    text-align: center;
    max-width: 100%;
  }
  &__txt{text-align: center;}
  &__wrap{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 64px;
  }
  @media only screen and (max-width : 991px){
    margin: 128px 0 64px;
    &__wrap{margin-top: 48px;}
  }
  @media only screen and (max-width : 767px){
    margin: 64px 0;
    &__title,
    &__txt{text-align: left;}
    &__wrap{
      margin-top: 48px;
      display: block;
    }
  }
}
.services-col{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  width: calc(50% - 16px);
  border-radius: 32px;
  padding: 64px;
  border: 1px solid #EBEDEE;
  &--blue{
    background-color: #3E2CB1;
    border-color: #3E2CB1;
    color: #fff;
    .services-col__title span{color: rgba(255, 255, 255, .5);}
    .services-col__list li i svg{fill: rgba(255, 255, 255, .5);}
  }
  &__sup{
    margin-bottom: 12px;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: .15px;
  }
  &__title{
    margin-bottom: 32px;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: .2px;
    span{
      font-size: 17px;
      line-height: 24px;
      letter-spacing: .5px;
      color: #7D8B92;
      font-weight: 400;
    }
  }
  &__text {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: .5px;
    color: #96A1A7;
    margin-bottom: 0;
    margin-left: 16px;
    margin-right: auto;
    justify-content: left;
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 14px;
    }

    @media (max-width: 767px) {
      padding: 10px;
    }
  }
  &__list{
    margin: 0 0 40px;
    font-size: 0;
    padding: 0;
    li{
      margin: 0 0 16px;
      padding: 0 0 0 28px;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: .5px;
      position: relative;
      i{
        position: absolute;
        top: 2px;
        left: 0;
        display: block;
        width: 20px;
        height: 20px;
        svg{
          fill: #96A1A7;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &__form{
    border: 1px solid #ebedee;
    border-radius: 4px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    padding: 16px;
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 14px;
    }
    @media (max-width: 767px) {
      padding: 10px;
    }
  }
  &__form-button-section{
    display: flex;
    align-items: baseline;
    @media (max-width: 767px) {
      flex-direction: column;
      margin-top: 8px;
    }
  }
  &__submit{
    margin-top: 8px;
    margin-left: 8px;
    flex-shrink: 0;
    @media (min-width: 768px) and (max-width: 991px) {
      margin-left: 8px;

    }
    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
  &__input{
    height: 50px;
    flex-grow: 1;
    &::-webkit-input-placeholder{opacity: 1; color: #96A1A7;}
    &::-moz-placeholder{opacity: 1; color: #96A1A7;}
    &:-moz-placeholder{opacity: 1; color: #96A1A7;}
    &:-ms-input-placeholder{opacity: 1; color: #96A1A7;}
    &:placeholder{opacity: 1;color: #96A1A7;}
    &:focus:required:valid{color: #96A1A7;}
    &:required:valid{color: #96A1A7;}
  }
  @media only screen and (max-width : 991px){
    width: calc(50% - 12px);
    padding: 32px 24px;
    &__sup{margin-bottom: 8px;}
    &__title{
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: .1px;
    }
    &__list{margin-bottom: 24px;}
    &__form{
      display: block;
      max-width: 100%;
    }
    &__submit{
      margin-top: 8px;
    }
  }
  @media only screen and (max-width : 767px){
    display: block;
    width: 100%;
    padding: 24px 16px 32px;
    &--blue{margin-top: 24px;}
    &__list{
      li{
        margin: 0 0 8px;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: .75px;
      }
    }
    &__sup{
      margin-bottom: 8px;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: .5px;
    }
  }
}
::placeholder {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: .5px;
  color: #96A1A7;
  margin-bottom: 0;
  margin-left: 16px;
  margin-right: auto;
  justify-content: left;
  @media (max-width: 767px) {
    font-size: 15px;
  }
}

//
//.form-answer {
//  display: none;
//}
