.main-sec {
  position: relative;
  padding: 25vh 0 0;
  margin-bottom: 120px;
  color: #fff;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;

  &__wrap {
    text-align: left;
    position: relative;
    z-index: 110;
    width: 850px;

    .main-sec__title {
      display: flex;
      flex-direction: column;
      max-width: 500px;

      span:first-child {
        font-size: 55px;
        font-weight: 800;
      }

      span:last-child {
        font-size: 60px;
        font-weight: 800;
      }
    }

    h3 {
      max-width: 540px;
      margin-bottom: 60px;
      font-size: 20px;
    }

    &.little-padding {
      @media only screen and (min-width: 767px) and (max-width: 1600px) {
        .main-sec__btn {
          margin-bottom: 40px !important;
        }
        .main-sec__txt {
          margin-bottom: 40px !important;
        }
        .main-sec-form {
          margin: 44px auto 212px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 15px;
    font-weight: 800;
    font-size: 55px;
  }

  &__txt {
    margin-bottom: 80px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__btn {
    display: inline-block;
    margin-bottom: 124px;
    background-color: #0098DA;
    color: white;
    padding: 12px 20px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;

    &:hover {
      background-color: rgba(0, 152, 218, 0.7);
    }
  }

  @media only screen and (max-width: 1199px) {
    &__title {
      margin-bottom: 30px;
      font-size: 40px;
      line-height: 48px;
      gap: 30px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 128px 0 88px;

    &__title {
      margin-bottom: 30px;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: .2px;
    }

    &__btn {
      width: 200px;
      margin-bottom: 32px;
      font-size: 17px;
      line-height: 24px;
      padding: 12px;
    }
  }
}

.main-sec-container {
  max-width: 1800px;
}

// h3:last-of-type {
//   display: none;
// }

.stats {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 60px;
}

.stat-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: white;
  font-size: 16px;
  position: relative;
  gap: 27px;

  &::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 44px;
    background: #FFFFFF;
    opacity: 0.05;
  }

  &:last-child::after {
    display: none;
  }

  >.stat-icon {
    width: 40px;
    height: 40px;
  }

  p {
    font-weight: 800;
    font-size: 18px;
  }
}

@media only screen and (max-width: 1366px) {
  h3:first-of-type {
    display: none;
  }

  h3:last-of-type {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .main-sec-container {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 830px) {
  .stats {
    flex-direction: column;
    align-items: flex-start;
  }

  .stat-item {
    gap: 15px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;

    &::after {
      display: none;
    }
  }
}

@media only screen and (max-width: 550px) {
  .main-sec__wrap .main-sec__title span:first-child {
    font-size: 40px;
    font-weight: 800;
  }

  .main-sec__wrap .main-sec__title span:last-child {
    font-size: 43px;
    font-weight: 800;
  }

  .main-sec__wrap h3 {
    max-width: 384px;
  }
}

@media only screen and (max-width: 385px) {
  .main-sec__wrap .main-sec__title span:first-child {
    font-size: 40px;
    font-weight: 800;
  }

  .main-sec__wrap .main-sec__title span:last-child {
    font-size: 43px;
    font-weight: 800;
  }

  .main-sec__wrap h3 {
    max-width: 290px;
  }

  .stat-item {
    width: 290px;
  }
}