//form resets
@mixin btn-reset{
  display: block;
  padding: 0;
  outline: 0!important;
  outline-color: transparent!important;
  outline-width: 0!important;
  outline-style: none!important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  border: none;
  background: none;
  box-shadow: none;
  transition: .25s ease;  
  cursor: pointer;
  border-radius: 0;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &::-moz-focus-inner {border: 0;}
  &:disabled {
    cursor: default;
    opacity: 1;
    user-select: none;	
  }
}
@mixin input-reset{
  display: block;
  width: 100%;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: .25s ease;  
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 0;
}