.footer {
  border-top: 1px solid #DDE1E2;
  background-color: #FAFAFA;

  .accordion-content {
    display: none;
    transition: max-height 0.3s ease-out;
  }

  .accordion-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::after {
      content: '';
      width: 20px;
      height: 12px;
      background-image: url(../img/svg/arrow-accordion.svg);
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      transition: transform 0.3s ease;
      transform: rotate(0deg);
    }

    &.open::after {
      transform: rotate(180deg);
    }
  }

  .accordion-header.open + .accordion-content {
    display: block;
  }

  @media only screen and (min-width: 1024px) {
    .accordion-header {
      cursor: default;
      &::after {
        display: none;
      }
    }

    .accordion-content {
      display: block;
    }
  }

  @media only screen and (max-width: 1024px) {
    .footer-main {
      display: block;
    }

    .footer-main__col {
      margin-bottom: 20px;
    }

    .footer-logo {
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

.footer-form{
  padding: 48px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DDE1E2;
  &__content{
    width: 50%;
    padding-right: 16px;
  }
  &__title{
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin-bottom: 4px;
  }
  &__txt{
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .75px;
  }
  &__form{
    width: 50%;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;    
  }
  &__submit{width: 160px;}
  &__input{
    width: calc(100% - 176px);
    height: 50px;
  }
  @media only screen and (max-width : 767px){
    padding: 32px 0;
    display: block;
    &__content{
      width: 100%;
      padding-right: 0;
      margin-bottom: 16px;
    }
    &__form{
      width: 100%;
      padding-left: 0;
      display: block; 
    }
    &__submit{width: 100%;}
    &__input{
      width: 100%;
      height: 48px;
      margin-bottom: 20px;
    }
  }
}

.footer-bottom{
  border-top: 1px solid #DDE1E2;
  padding: 24px 0 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #DDE1E2;
  @media only screen and (max-width : 461px){
    padding: 32px 0;
    display: block;
    text-align: center;
  }
}

.footer-copyright{
  font-size: 15px;
  line-height: 20px;
  letter-spacing: .75px;
  margin: 0;
  @media only screen and (max-width : 461px){
    text-align: left;
  }
}

.footer-main{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 48px 0;
  &__col{
    width: 271px;
    &-title{
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: .75px;
      margin-bottom: 25px;
    }
  }

  @media only screen and (max-width : 1024px){
    display: block;
    padding: 32px 0;
    &__col{
      width: 100%;
      margin-bottom: 32px;
      &:last-child{margin-bottom: 0;}
      &-title{margin-bottom: 24px;}
    }
  }
}

.footer-logo{
  display: block;
  width: 169px;
}

.footer-nav{
  ul{
    li{
      margin-bottom: 16px;
      &:last-child{margin-bottom: 0;}
      a{
        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: .75px;
        color: #1B1B1B;
        transition: transform 0.3s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.footer-txt{
  margin: 16px 0 16px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: .75px;
}

.footer-soc{
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  &__link{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #EBEDEE;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 1024px) {
  .footer-nav ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    li {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 414px) {
  .footer-nav ul {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    li {
      margin-bottom: 16px;
    }
  }
}

input {
  @media only screen and (max-width : 767px){
    font-size: 16px !important;
  }
}

.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
}

.contact-item:hover {
  color: #555555;
}

.contact-item img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.footer .contact-list a {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .contact-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-item img {
    margin-right: 8px;
  }
}