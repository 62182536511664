.partners {
  text-align: center;
  margin-bottom: 120px;

  &__title {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1B1B1B;

    @media only screen and (max-width: 1024px) {
      font-size: 25px;
    }

    @media only screen and (max-width: 414px) {
      font-size: 20px;
    }
  }

  &__carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 20px;
    margin-left: 40px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__card {
    flex: 0 0 auto;
    width: 443px;
    height: 120px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    scroll-snap-align: start;
    transition: transform 0.3s ease, filter 0.3s ease;

    img {
      max-width: 100%;
      max-height: 100%;
      filter: grayscale(100%);
      transition: filter 0.3s ease;
    }

    &:hover img {
      filter: grayscale(0%);
    }

    &:hover {
      transform: scale(1.05);
    }

    &:last-child {
      margin-right: 40px;
    }
  }

  @media only screen and (max-width: 1535px) {
    .partners__carousel {
      margin-left: 0;
    }

    #partners-container {
      max-width: 100%;
      width: 96%;
    }
  }

  @media only screen and (max-width: 1366px) {
    &__card {
      width: 312px;
    }
  }

  @media only screen and (max-width: 1024px) {
    &__card {
      img {
        filter: none;
      }
    }
  }

  @media only screen and (max-width: 414px) {
    &__card {
      width: 200px;
      height: 77px;
    }
  }
}

#partners-container {
  max-width: 100vw;
  width: 100%;
  padding: 0;
}