.article{
  margin-bottom: 48px;
  &__wrap{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
  }
  &__aside{
    width: 384px;
    position: fixed;
    right: calc((100vw - 1216px) / 2);
    @media (max-width: 1200px) {
      right: calc((100vw - 960px) / 2);
    }
    &.end-fixing {
      position: absolute;
      top: calc(100% - 300px);
      right: -16px;
    }
  }
  &__content{
    width: calc(100% - 384px - 32px);
    max-width: 696px;
  }
  &__header{margin-bottom: 32px;}
  &__main{
    font-size: 17px;
    line-height: 28px;
    letter-spacing: .5px;
    p{margin: 16px 0;}
    img{
      display: block;
      width: 100%;
      margin: 16px 0;
    }
    h2{
      margin: 16px 0;
      font-weight: bold;
      font-size: 1.5em;
      line-height: 28px;
      letter-spacing: .15px;
    }
    h3{
      font-size: 1.2em;
    }
  }
  &__section{
    margin-top: 32px;
    border-top: 1px solid #dde1e2;

    ul {
      padding-left: 20px;
    }
    li {
      list-style-type: disc;
    }
  }
  &__footer{
    border-top: 1px solid #dde1e2;
    border-bottom: 1px solid #dde1e2;
    padding: 15px 0;
    margin: 48px 0;
  }
  @media only screen and (max-width : 991px){
    &__aside{display: none;}
    &__content{
      width: 100%;
      max-width: unset;      
    }
  }
}
.article-bar{margin: 48px 0;}
.article-time{
  display: block;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: .4px;
  color: #7D8B92;
}
.article-share{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  li{
    margin-right: 24px;
    &:last-child{margin-right: 0;}
    a{
      display: block;
      width: 22px;
      height: 22px;
      svg{
        fill: #000;
        width: 22px;
        height: 22px;
      }
    }
  }
}

.squared-list-type {

  li {
    position: relative;
    
    &::before {
      position: absolute;
      top: 5px;
      left: -20px;
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      transform: translateY(40%);
      background: #007bff;
    }
  }
}

// Оформление цитат
.article__section {
  .blockquote {
    background-color: #fafafa;
    padding: 20px 30px;
    border-radius: 10px;
    margin-bottom: 20px;

    .article-quote {
      font-style: italic;
      margin-bottom: 15px;
    }

    .author {
      text-align: right;
      font-weight: bold;
      margin-top: 20px;
      color: #333;
    }
  }

  p {
    margin-bottom: 15px;
  }
}

// выравнивание иконок "поделиться"
.article-share {
  display: flex;
  justify-content: left;
  padding: 0;
  margin: 0;
}

.article-share li {
  margin: 0 10px;
}

.article-share a {
  display: flex;
  align-items: center;
}

.center-section {

  img {
    display: block;
    margin: 10px auto;
    border-radius: 10px;
    max-width: 80%;
    height: auto;
  }

  p {
    text-align: center;
    margin-bottom: 30px;
  }
}