.cite-slider{
  margin: 100px 0;
  position: relative;
  .slick-track{
    display: flex;
    align-items: center;
  }
  &__quote{
    display: block;
    padding: 0;    
    position: relative;
    z-index: 2;
    text-align: left;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: .2px;
    color: #005782;
    &::before{
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      width: 96px;
      height: 76px;
      background: center center no-repeat;
      background-size: contain;
      top: -28px;
      left: -30px;
      background-image: url(../img/svg/quote-blue.svg);
    }    
  }
  &__author-name{
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: .15px;
    margin-bottom: 4px;
  }
  &__author-status{
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .75px;
    color: #7D8B92;
  }
  &__slide{
    padding-top: 30px;
    max-width: calc(100vw - 280px);
    margin: 0 auto;
    &-wrap{
      //max-width: 1010px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-ava{
      width: 192px;
      height: 192px;
      border-radius: 50%;
      overflow: hidden;    
      flex-shrink: 0;
      margin-bottom: 32px;
    }
    &-bar{
      width: 192px;
      text-align: center;
    }
    &-main{
      width: calc(100% - 192px - 64px);
    }
  }
  @media only screen and (max-width : 1199px){
    &__quote{
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
    }
    &__author-name{
      font-size: 17px;
      line-height: 24px;
      letter-spacing: .5px;
      margin-bottom: 2px;
    }
    &__slide{
      max-width: calc(100vw - 140px);
      &-ava{
        width: 160px;
        height: 160px;
      }
      &-bar{width: 160px;}
      &-main{
        width: calc(100% - 160px - 56px);
      }
    }
  }
  @media only screen and (max-width : 767px){
    margin: 40px 0;
    .slick-track{align-items: flex-start;}
    &__quote{
      text-align: center;
      &::before{
        width: 69px;
        height: 54px;
        top: -13px;
        left: 0;
      }    
    }
    &__slide{
      max-width: 100%;
      padding: 0;
      &-wrap{display: block;}
      &-ava{
        width: 96px;
        height: 96px;
        margin: 0 auto 32px;
      }
      &-bar{
        width: 100%;
        margin-bottom: 30px;
      }
      &-main{width: 100%;}
    }
  }
}

.cite-slider-arrow{
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  width: 140px;
  height: 140px;
  svg{
    width: 100%;
    height: 100%;
    display: block;
    fill: #08c;
  }
  &__prev{left: 3vw;}
  &__next{right: 3vw;}
  @media only screen and (max-width : 1599px){
    &__prev{left: 1vw;}
    &__next{right: 1vw;}
  }
  @media only screen and (max-width : 1499px){
    &__prev{left: 0;}
    &__next{right: 0;}
  }
  @media only screen and (max-width : 1199px){
    width: 70px;
    height: 70px;
  }
  @media only screen and (max-width : 767px){
    top: 48px;
  }
}