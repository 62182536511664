.easy-balance{
  margin: 200px 0 128px;
  &__wrap{
    max-width: 1135px;
    height: 616px;
    margin: 0 auto;
    position: relative;
  }
  &__content{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 592px;
  }  
  @media only screen and (max-width : 991px){
    margin: 128px 0 200px;
    &__wrap{height: 545px;}
  }
  @media only screen and (max-width : 767px){
    margin: 64px 0;
    &__wrap{height: auto;}
    &__content{
      text-align: left;
      position: static;
      transform: none;
      max-width: unset;
      margin-bottom: 48px;
    }
    &__list{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 48px;      
    }
  }  
}
.easy-balance-item{
  position: absolute;
  text-align: center;
  &__pic{
    width: 64px;
    margin: 0 auto 16px;
  }
  &__txt{
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: .5px;
    white-space: nowrap;
  }
  &__sub{
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .75px;
    color: #96A1A7;
    white-space: nowrap;
  }
  &:nth-child(1){
    top: 20px;
    left: 230px;
  }
  &:nth-child(2){
    top: 0;
    right: 323px;
  }
  &:nth-child(3){
    top: 248px;
    left: 0;
  }
  &:nth-child(4){
    top: 228px;
    right: 0;
  }
  &:nth-child(5){
    bottom: 0;
    left: 324px;
  }
  &:nth-child(6){
    bottom: 12px;
    right: 213px;
  }
  @media only screen and (max-width : 991px){
    &:nth-child(1){
      top: 23px;
      left: 160px;
    }
    &:nth-child(2){right: 159px;}
    &:nth-child(5){left: 184px;}
    &:nth-child(6){
      bottom: 30px;
      right: 105px;
    }
  }
  @media only screen and (max-width : 767px){
    position: static;
    text-align: left;
    &__pic{margin: 0 0 16px;}
    &__txt,&__sub{white-space: normal;}
    
  }  
}
