.adgram-features {
  margin-bottom: 80px;
  position: relative;

  .container {
    position: relative;
  }

  // Вертикальная линия
  &::before {
    content: '';
    position: absolute;
    top: -180px;
    bottom: -150px;
    left: 50%;
    width: 2px;
    background-color: rgba(27, 27, 27, 0.1);
    transform: translateX(-50%);
  }

  .adgram-feature {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 150px 0;
    position: relative;
    gap: 10%;

    &__image {
      max-width: 45%;

      img {
        height: auto;
        max-width: 100%;
        border-radius: 20px;
      }
    }

    &__image img {
      position: relative;
      z-index: 101;
    }

    .img-left,
    .img-center,
    .img-right {
      max-width: 38%;
      height: auto;
      object-fit: cover;
      border: 2px solid #1B1B1B1A;
      border-radius: 10px;
    }

    .img-left {
      position: relative;
      z-index: 1;
      margin-right: -50px;
    }

    .img-center {
      position: relative;
      z-index: 2;
      transform: translateY(-25px);
    }

    .img-right {
      position: relative;
      z-index: 1;
      margin-left: -50px;
    }


    .img-rightblock {
      position: relative;
      z-index: 1;
    }
      
    &__container {
      display: flex;
      justify-content: left;
      position: relative;
      max-width: 45%;
      z-index: 2;
    }

    &__content {
      max-width: 45%;

      p {
        font-size: 40px;
        font-weight: 600;
        color: #1B1B1B;

        .highlight {
          color: #0098DA;
        }
      }
    }

    // Горизонтальная линия для нечетных блоков (влево)
    &:nth-child(odd)::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 50%;
      width: 25%;
      height: 2px;
      background-color: rgba(27, 27, 27, 0.1);
      z-index: 1;
    }

    // Горизонтальная линия для четных блоков (вправо)
    &:nth-child(even)::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 25%;
      height: 2px;
      background-color: rgba(27, 27, 27, 0.1);
      z-index: 1;
    }

    // Кружок на пересечении вертикальной и горизонтальной линии
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      background-color: #fff;
      border: 2px solid rgba(27, 27, 27, 0.1);
      border-radius: 50%;
      z-index: 2;
    }

    &--reverse {
      flex-direction: row-reverse;

      &__content {
        margin-left: 0;
        margin-right: 40px;
      }
    }
  }

  @media only screen and (max-width: 1090px) {
    .adgram-feature__image {
      display: flex;
      justify-content: center;
    }
  }
}

.feature-description {
  display: block;
  font-size: 22px;
  font-weight: normal;
  color: #1B1B1B;
  opacity: 0.8;
  margin-top: 30px;
}

.payment-models {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  gap: 40px;
  margin-top: 80px;

  .payment-model {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 15px;
    width: 35%;
    margin-bottom: 20px;

    .payment-icon {
      width: 50px;
      height: 50px;
    }

    .payment-text {
      h3 {
        font-size: 22px;
        font-weight: 800;
      }

      p {
        font-size: 14px;
        color: #36353B;
        opacity: 0.9;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .payment-model {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1496px) {
  .content-left {
    max-width: 600px;
  }
}

@media only screen and (max-width: 1337px) {
  .adgram-features .adgram-feature__content p {
    font-size: 25px;
  }

  .feature-description {
    font-size: 16px;
  }

  .content-left {
    max-width: 400px;
  }

  .payment-models {
    gap: 20px;
    margin-top: 40px;
  }

  .adgram-features .adgram-feature {
    justify-content: space-between;
    gap: 0;
    margin: 80px 0;
  }

  .adgram-features .adgram-feature__image {
    max-width: 48%;
  }

  .adgram-features .adgram-feature__container {
    max-width: 48%;
  }

  .adgram-features .adgram-feature__content {
    max-width: 48%;
  }

  .payment-models .payment-model {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .adgram-feature {
    flex-direction: column;

    &--reverse {
      flex-direction: column;
      margin: 0 0 60px;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .adgram-features .adgram-feature__image {
    max-width: 100%;
  }

  .adgram-features .adgram-feature__content {
    margin: 20px 0;
    max-width: 100%;
  }

  .adgram-features::before {
    display: none;
  }

  .adgram-features .adgram-feature__image img {
    max-width: 100%;
  }
  .adgram-features .adgram-feature--reverse {
    flex-direction: column;
  }

  .payment-models {
    width: auto;
  }

  .adgram-features .adgram-feature__container {
    justify-content: center;
    max-width: 100%;
  }

  .adgram-features .adgram-feature__content p {
    font-size: 20px;
  }
}