.earn{
  position: relative;
  margin: 116px 0 160px;
  .container{
    position: relative;
    z-index: 2;
  }
  &__title{
    text-align: center;
    margin-bottom: 96px;
    max-width: unset;
  }
  &__wrap{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 160px;
    border-bottom: 1px solid #DDE1E2;
  }
  @media only screen and (max-width : 991px){
    margin: 128px 0;
    &__wrap{padding-bottom: 80px;}
  }
  @media only screen and (max-width : 767px){
    margin: 80px 0;
    &__bg{
      left: 50%;
      transform: translateX(-50%);
      width: 140%;
      max-width: unset;
    }
    &__title{
      text-align: left;
      margin-bottom: 48px;
    }
    &__wrap{
      display: block;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
.earn-col{
  width: calc((100% - 96px) / 4);
  position: relative;
  &__icn{
    position: relative;
    margin-bottom: 16px;
  }
  &__title{
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: .15px;
  }
  &__txt{
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .75px;
    color: #7D8B92;
  }
  &::after{
    content: '';
    display: block;
    width: calc(100% - 72px);
    height: 1px;
    position: absolute;
    top: 24px;
    right: 0;
    border-top: 1px dashed #CED3D6;
  }
  &:last-child::after{display: none;}
  @media only screen and (max-width : 767px){
    width: 100%;
    margin-bottom: 40px;
    padding-left: 64px;
    position: relative;
    &:last-child{margin-bottom: 0;}
    &__icn{
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      width: 1px;
      height: calc(100% - 56px + 32px);
      top: 56px;
      right: auto;
      left: 24px;
      border-top: none;
      border-left: 1px dashed #CED3D6;
    }
  }
}