.channel-card{
  width: 416px;
  position: relative;
  &::before{
    content: '';
    display: block;
    width: 510px;
    height: 632px;
    background: url(../img/svg/phone.svg) 0 bottom no-repeat;
    background-size: contain;
    position: absolute;
    left: 60px;
    bottom: -52px;
  }
  &__wrap{
    width: 100%;
    position: relative;
    z-index: 2;
  }
  &__main{
    padding: 16px;
    background: #fff;
    border: 1px solid #EBEDEE;
    border-radius: 12px;
    min-height: 428px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    filter: drop-shadow(0px 8px 24px rgba(43, 51, 56, 0.16));
  }
  &__txt{
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .75px;
    p{
      margin-bottom: 18px;
      &:last-child{margin-bottom: 0;}
    }
  }
  &__title{
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #4080C7;
  }
  &__pic{
    padding-left: 4px;
    border-left: 2px solid #4080C7;
  }
  &__time{
    display: block;
    margin-top: 8px;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    letter-spacing: .4px;
    color: #7D8B92;
  }
  &__more{
    display: block;
    margin-top: 8px;
    width: 100%;
    height: 48px;
    filter: drop-shadow(0px 8px 24px rgba(43, 51, 56, 0.16));
    border-radius: 12px 12px 24px 24px;
    line-height: 48px;
    font-size: 15px;
    text-align: center;
    letter-spacing: .75px;
    color: #000;
    background-color: #F8F9F9;
  }
  @media only screen and (max-width : 991px){
    width: 345px;
    &::before{
      width: 422px;
      height: 524px;
      left: 50px;
      bottom: -43px;
    }
    &__main{
      padding: 16px 13px 13px;
      min-height: 355px;
    }
    &__txt{
      margin-bottom: 7px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: .65px;
      p{
        margin-bottom: 15px;
        &:last-child{margin-bottom: 0;}
      }
    }
    &__title{
      margin-bottom: 3px;
      font-size: 12px;
      line-height: 16px;
    }
    &__pic{padding-left: 3px;}
    &__time{margin-top: 6px;}
    &__more{
      margin-top: 7px;
      height: 40px;
      line-height: 40px;
    }
  }
  @media only screen and (max-width : 767px){
    width: 280px;
    &::before{
      width: 344px;
      height: 425px;
      left: 40px;
      bottom: -38px;
    }
    &__main{
      padding: 12px 11px;
      min-height: 288px;
    }
    &__txt{
      margin-bottom: 4px;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: .92px;
      p{
        margin-bottom: 10px;
        &:last-child{margin-bottom: 0;}
      }
    }
    &__title{
      margin-bottom: 3px;
      font-size: 12px;
      line-height: 16px;
    }
    &__more{
      margin-top: 5px;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
    }
  }  
}