.statistics {
  margin-bottom: 80px;

  &__content {
    background-color: #FAFAFA;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    position: relative;
    gap: 60px;

    @media only screen and (max-width: 1280px) {
      flex-direction: column-reverse;
      align-items: center;
      padding: 30px 20px;
      gap: 0;

      .statistics__image {
        max-width: 100%;
        margin-bottom: 20px;
      }

      .statistics__image > img {
        content: url('../img/pic/statistics-thin.png');
      }

      .statistics__text {
        text-align: left;
        max-width: 100%;
        margin-top: -50px;
      }
    }

    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse;
      padding: 10px 5px;
    }
  }

  &__text {
    max-width: 500px;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  &__description {
    font-size: 35px;
    font-weight: 600;
    color: #000000;

    @media only screen and (max-width: 1024px) {
      font-size: 25px;
    }

    @media only screen and (max-width: 414px) {
      font-size: 20px;
    }
  }

  &__image {
    position: relative;

    > img {
      width: 100%;
      border-radius: 20px;
      margin-top: -30px;
      position: relative;
      top: -60px;
    }

    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }

    .extra-block {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      width: 232px;
      height: 108px;
      background-color: #fff;
      border: 3px solid #FAFAFA;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      .block-title {
        font-size: 18px;
        font-weight: 800;
      }

      .block-subtitle {
        font-size: 14px;
      }

      @media only screen and (max-width: 1280px) {
        width: 200px;
        height: 90px;
        padding: 12px;

        .block-title {
          font-size: 16px;
          font-weight: 800;
        }

        .block-subtitle {
          font-size: 12px;
        }

        .icon {
          width: 55px;
          height: 55px;
        }
      }

      @media only screen and (max-width: 1024px) {
        width: 180px;
        height: 80px;
        padding: 10px;

        .block-title {
          font-size: 14px;
        }

        .block-subtitle {
          font-size: 10px;
        }

        .icon {
          width: 45px;
          height: 45px;
        }
      }

      @media only screen and (max-width: 768px) {
        width: 160px;
        height: 70px;
        padding: 8px;

        .block-title {
          font-size: 12px;
        }

        .block-subtitle {
          font-size: 10px;
        }

        .icon {
          width: 40px;
          height: 40px;
        }
      }

      @media only screen and (max-width: 414px) {
        width: 140px;
        height: 60px;
        padding: 6px;

        .block-title {
          font-size: 11px;
        }

        .block-subtitle {
          font-size: 9px;
        }

        .icon {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

.upper-left {
  top: -120px;
  left: 65px;
  transform: rotate(-10deg);

  @media only screen and (max-width: 1280px) {
    top: -40px;
    left: 20px;
  }

  @media only screen and (max-width: 1024px) {
    top: -40px;
    left: 10px;
  }

  @media only screen and (max-width: 768px) {
    top: -80px;
    left: 5px;
  }

  @media only screen and (max-width: 414px) {
    top: -80px;
    left: 5px;
  }
}

.lower-right {
  bottom: 10px;
  right: 20px;
  transform: rotate(10deg);

  @media only screen and (max-width: 1280px) {
    bottom: 70px;
    right: 30px;
  }

  @media only screen and (max-width: 1024px) {
    bottom: 50px;
    right: 20px;
  }

  @media only screen and (max-width: 768px) {
    bottom: 40px;
    right: 10px;
  }

  @media only screen and (max-width: 414px) {
    bottom: 40px;
    right: 15px;
  }
}

.highlight {
  color: #0098DA;
}
