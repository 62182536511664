.balance {
  margin-bottom: 120px;

  &__content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 50px;
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 20px;
    max-width: 600px;
  }

  &__description {
    font-size: 22px;
    line-height: 22px;
    color: #1B1B1B;
    margin-bottom: 40px;
    max-width: 600px;
  }

  &__options {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__option {
    background-color: #FAFAFA;
    border-radius: 20px;
    padding: 20px;
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  &__icon {
    width: 50px;
    height: 50px;
    background-color: #0098DA;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__option-text {
    strong {
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
    }

    p {
      font-size: 14px;
      color: #7D8B92;
      opacity: 0.8;
    }
  }

  &__image {
    flex: 1;
    background-color: #F2F3F8;
    border: 3px solid #1B1B1B1A;
    border-radius: 20px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  @media only screen and (max-width: 1024px) {
    &__options {
      flex-direction: column;
      width: 100%;
      gap: 15px;
    }

    &__option {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1336px) {
    &__title {
      font-size: 25px;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__option {
      background-color: #fafafa;
      border-radius: 20px;
      padding: 10px;
      gap: 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    }

    &__icon {
      width: 50px;
      height: 50px;
      background-color: #0098da;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 800px) {
    &__content {
      flex-direction: column-reverse;
      gap: 40px;
    }

    &__image {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    &__title {
      margin-bottom: 20px;
    }

    &__description {
      margin-bottom: 25px;
    }
  }

  @media only screen and (max-width: 414px) {
    &__title {
      font-size: 20px;
    }

    &__description {
      font-size: 16px;
      margin-bottom: 25px;
    }
  }
}