.steps{
  margin: 312px 0 274px;
  position: relative;
  background-color: #EDF6FA;
  padding-top: 64px;
  padding: 1px 0;
  .sec-title{
    text-align: center;
    max-width: unset;
  }
  &__before {
    img {
      height: 100%;
    }
  }
  &__before,
  &__after{
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    img{
      width: 100%;
    }
  }
  &__before{
    top: 0;
    transform: translateY(calc(-100% + 12px));
    margin-bottom: -1px;
  }
  &__after{
    top: 100%;
    margin-top: -1px;
  }
  @media only screen and (max-width : 767px){
    margin: 128px 0;
    padding: 32px 0 22px;
    .sec-title{
      text-align: left;
      margin-bottom: 64px;
    }
    &__before,
    &__after{height: 64px;}
  }
}

.steps-row{
  margin: 128px 0 168px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &:nth-child(odd){
    flex-direction: row-reverse;
  }
  &__content{
    width: 488px;
    position: relative;
    z-index: 4;
  }
  &__fig{
    width: 488px;
    height: 452px;
    position: relative;
  }
  &__title{
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: .2px;
  }
  &__txt{
    font-size: 17px;
    line-height: 24px;
    letter-spacing: .5px;
    margin-bottom: 32px;
    &:last-child{margin-bottom: 0;}
    p{margin-bottom: 1em;}
  }
  &__oval{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
    &--1{
      width: 912px;
      height: 722px;
    }
    &--2{
      width: 808px;
      height: 736px;
    }
    &--3{      
      width: 1129px;
      height: 1114px;
    }
    &--4{      
      width: 1104px;
      height: 992px;
    }
    &--5{      
      width: 912px;
      height: 722px;
    }
  }
  &__pic{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 452px;
    height: 452px;
    &__anim {
      position: absolute;
      max-width: 112% !important;
      transition: transform .4s ease-out;
      &-hand {
        max-width: 60% !important;
        right: -28%;
        top: 10%;
      }
      &__subs {
        max-width: 130% !important;
      }
      &-head {
        bottom: 77%;
        @media (max-width: 767px) {
          bottom: 20%;
        }
      }
      &-btn {
        max-width: 40% !important;
        bottom: -5%;
        @media (max-width: 767px) {
          bottom: -60%;
        }
      }
      &-ico {
        max-width: 20% !important;
        &:nth-of-type(2) {
          top: 52%;
          right: 0;
          max-width: 10% !important;
        }
        &:nth-of-type(3) {
          top: 2%;
          right: -20%;
        }
        &:nth-of-type(4) {
          top: 20%;
          right: 70%;
        }
        &:nth-of-type(5) {
          top: 48%;
          right: 80%;
        }
      }
      &-card {
        max-width: 70% !important;
        &:nth-of-type(2) {
          left: -20%;
          top: -5%;
        }
        &:nth-of-type(3) {
          right: -20%;
          top: 20%;
        }
      }
      &-calendar-h {
        max-width: 90% !important;
        right: -36%;
        top: 10%;
      }
      &-phone {
        max-height: 160% !important;
        width: auto !important;
        transition: transform .4s ease-out;
      }
    }
    &--mob{display: none;}
    img{max-width: unset;}
    &--1{      
      img{width: 704px;}
    }
    &--2{
      img{
        width: 808px;
      }
    }
    &--3{
      img{width: 489px;}
    }
    &--4{
      img{width: 808px;}
    }
    &--5{
      left: 60px;
      img{
        width: 808px;        
      }
    }
    &--6{
      img{width: 704px;}
    }
    &--7{
      img{width: 488px;}
    }
    &--8{
      width: auto !important;
      img{width: auto;}
    }
    &--9{
      img{width: 808px;}
      @media only screen and (max-width : 767px){
        height: 30vh !important;
      }
    }
  }
  @media only screen and (max-width : 1199px){
    margin: 128px 0 168px;
    &__content{
      width: 340px;
      flex-shrink: 0;
    }
    &__fig{
      width: 277px;
      height: 377px;
    }
    &__title{
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: .1px;
    }
    &__txt{
      font-size: 17px;
      line-height: 24px;
      letter-spacing: .5px;
    }
    &__oval{
      transform: translate(-50%,-50%) scale(.7);
      &--1{margin-left: 120px;}
      &--2{margin-left: -40px;}
      &--3{margin-left: 80px;}
      &--4{margin-left: -80px;}
      &--5{display: none;}
    }
    &__pic{
      &--1{ img{width: 580px;}}
      &--2{
        margin-left: -80px;
        img{width: 460px;}
      }
      &--3{
        margin-left: -80px;
        img{width: 380px;}
      }
      &--4{
        margin-left: -80px;
        img{width: 358px;}
      }
      &--5{
        margin-left: -140px;
        img{width: 458px;}
      }
      &--6{
        margin-left: -80px;
        img{width: 505px;}
      }
      &--7{
        margin-left: -90px;
        img{width: 372px;}
      }
      &--8{
        margin-left: -110px;
        img{width: 465px;}
      }
      &--9{
        margin-left: -47px;
        img{width: 476px;}
      }
    }
  }
  @media only screen and (max-width : 767px){
    margin: 0 0 80px;
    display: block;
    &__content{
      width: 100%;
      margin-bottom: 80px;
    }
    &__fig{
      width: 100%;
      height: auto;
    }
    &__title{
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: .1px;
    }
    &__txt{margin-bottom: 24px;}
    &__oval{display: none;}
    &__pic{
      position: static;
      transform: none;
      width: 100%;
      height: auto;
      margin: 0 0 32px;
      &--mob{display: block;}
      &--desc{display: none;}
      &--1{
        height: 242px;
        img{
          margin-right: 30%;
          width: auto;
          height: 380px;
        }
      }
      &--2{
        img{
          width: 100%;
          transform: none;
        }
      }
      &--3{
        img{width: 100%;}
      }
      &--4{
        img{
          width: auto;
          height: 260px;
        }
      }
      &--5{
        img{
          width: auto;
        }
      }
      &--6{
        img{
          width: auto;
          height: 300px;
        }
      }
      &--7{
        img{width: 100%;}
      }
      &--8{
        img{
          width: auto;
          height: 260px;
        }
      }
      &--9{
        img{
          width: auto;
          height: 260px;
        }
      }
    }
  }
}
