.header{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;

  &__wrap{
    width: 100%;
    max-width: 1824px;
    padding: 16px;  
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__burger{
    display: none;
  }

  &__wrap,
  &__right,
  &__left{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-logo{
    margin-right: 150px;
  }

  .lang{
    margin-right: 24px;
    cursor: pointer;

    @media only screen and (max-width: 430px) {
      margin-right: 60px;
    }
  }
}

@media only screen and (max-width: 1720px) {
  .header .header-logo {
      margin-right: 50px;
}

@media only screen and (max-width : 1590px) {
  .header .header-logo{
    margin-right: 30px;
  }

  &__burger {
    display: block;
    margin-left: auto;
    order: 1;
  }
}

@media only screen and (max-width: 1595px) {
  .header .header-logo{
    margin-right: 30px;
  }
  
  .header__wrap {
    justify-content: flex-start;
    position: relative;
  }

  .header-logo {
    margin: 0 auto;
  }

  .header__burger {
    display: block;
    padding: 10px;
    background: #0098DA;
    border-radius: 5px;
    margin-right: auto;
  }
}

  @media only screen and (max-width: 430px) {
    .header .header-logo{
      margin-right: 10px;
    }

    .header__wrap {
      justify-content: space-between;
      position: relative;
    }

    .header-logo {
      margin-left: 0;
    }

    .header__burger {
      display: block;
      position: absolute;
      right: 16px;
      order: 2;
      margin-right: 0;
    }
  }
}

.lang_select {
  position: absolute;
  top: 60px;
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  li {
    padding: 5px;

    a {
      white-space: nowrap;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: .75px;
      border-radius: 4px;
      cursor: pointer;
    }

    .white {
      color: white;

      &:hover {
        color: white;
      }
    }

    .black {
      color: rgba(0, 0, 0, 0.75);

      &:hover {
        color: black;
      }
    }
  }
}

.header-logo{
  display: block;
  width: 169px;

  @media only screen and (max-width : 1280px){
    margin: 0 auto;
  }

  @media only screen and (max-width : 416px){
    max-width: 140px;
  }
}

.header-nav{
  ul{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;

    li{
      margin-right: 16px;

      a{
        display: block;
        padding: 16px;
        white-space: nowrap;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: .75px;
        color: #FFFFFF;
        border-radius: 4px;

        &:hover {
          font-size: 16px;
          transform: scale(1.05);
        }
      }
    }    
  }
  &--page{
    ul li a {
      color: #000000;
      font-weight: 600;
    }
  }
  @media only screen and (max-width : 1299px){
    ul li{margin-right: 8px;}
  }

  @media only screen and (max-width : 1595px){
    display: none;
  }
}

.header-enter{
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  background-color: transparent;
  border: 2px solid #0098DA;
  border-radius: 30px;
  padding: 12px 24px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  margin-right: 24px;
  &:hover{
    color: #0098DA;
  }
  &--page{
    color: #0098da;
    &:hover{color: #08c;}
  }
  @media only screen and (max-width : 760px){
    display: none;
  }
}

.header-btn{
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #0098DA;
  border: none;
  border-radius: 30px;
  padding: 14px 28px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  &:hover {
    background-color: rgba(0, 152, 218, 0.7);
  }
  @media only screen and (max-width : 760px){
    display: none;
  }
}

.mob-menu-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0 ,0 ,0 ,.6);
  z-index: 990;
  display: none;
  &.open{
    display: block;
    animation: fadeIn .25s linear both;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.mob-menu{
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 414px;
  height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  transition: transform .25s;
  transform: translateX(-110%);
  &.open{transform: translateX(0);}
  &__wrap{
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
  }
  &__close{
    position: absolute;
    z-index: 3;
    top: 20px;
    left: 16px;
  }
  &__main{padding-top: 16px;}
  &__logo{
    display: block;
    width: 135px;
    margin: 0 auto;
  }
  &__nav{
    border-top: 1px solid #DDE1E2;
    margin-top: 16px;
    padding: 0 24px;
    ul li{
      a{
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DDE1E2;
        color: #62696D;
        letter-spacing: .15px;
        font-size: 19px;
        line-height: 28px;
        
        i{
          font-size: 0;
          display: block;
          svg{display: block;}
        }
      }
      
    }
  }
  &__footer{padding: 24px;}
  &__btn{
    margin-top: 20px;
    &:first-child{margin-top: 0;}
  }
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -2;
  pointer-events: none;
}

.video-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  height: 100vh;
}

.current_lang {
  margin-right: 8px;
}