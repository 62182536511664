.blog-intro {
  margin: 50px 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10%;
  }

  &__image {
    max-width: 50%;
    height: auto;
    border-radius: 10px;
  }

  &__text {
    max-width: 50%;
    font-size: 18px;
    line-height: 1.6;

    p:first-of-type {
      margin-bottom: 20px;
    }

    .highlight {
      color: #0098DA;
    }

    ul {
      list-style-type: "none";
      padding-left: 0;

      li {
        position: relative;
        padding-left: 0;

        &::before {
          content: "-";
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    &__content {
      flex-direction: column;
    }

    &__image,
    &__text {
      max-width: 100%;
    }

    &__image {
      margin-bottom: 20px;
    }
  }
}

.articles-section {
  margin: 100px 0;

  .articles-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .article-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    .article-title {
      font-size: 18px;
      font-weight: 600;
      padding: 0 10px;
      z-index: 1;
    }

    .article-image {
      width: 100%;
      max-height: 260px;
      border-radius: 10px;
      margin-bottom: 20px;
      object-fit: cover;
    }

    a {
      width: 100%;
    }
  }
}

// Делаем блок статьи целиком кликабельным без оборачивания всего элемента в ссылку:
.article-item {
  position: relative;
}
.article-link {
  text-decoration: none;
  color: #000;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}
.article-title,
.article-image {
  position: relative;
}
.underline {
  text-decoration: underline;
}

.blog-recommendation{
  flex-grow: 1;
  max-width: 696px;
  &__title{
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: .1px;
  }
  @media only screen and (max-width : 991px){
    width: 100%;
    max-width: unset;
  }
}

.blog-banner{
  background-image: linear-gradient(180deg, #18ABF4 0%, #1892CF 94.94%);
  overflow: hidden;
  padding: 48px 24px 48px;
  color: #fff;
  border-radius: 0;
  position: relative;
  border-radius: 16px;
  &::before,
  &::after{
    content: '';
    display: block;
    position: absolute;
    background: center center no-repeat;
    background-size: contain;
  }
  &::before{
    background-image: url(../img/svg/blog-banner-left.svg);
    width: 361px;
    height: 510px;
    top: -15px;
    left: -169px;
  }
  &::after{
    background-image: url(../img/svg/blog-banner-right.svg);
    width: 265px;
    height: 333px;
    top: 161px;
    left: 216px;
  }
  &__img,
  &__title,
  &__form{
    position: relative;
    z-index: 2;
  }
  &__img{    
    width: 142px;
    margin-bottom: 32px;
  }
  &__title{
    margin-bottom: 32px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: .1px;
  }
  &__form{margin: 0;}
  &__submit{
    margin-top: 16px;
    display: block;
    width: 100%;
  }
  &--row{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 48px;
    .blog-banner__img{ margin: 0 48px 0 0;}
    .blog-banner__title{
      margin-bottom: 16px;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: .15px;
    }
    .blog-banner__form{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .blog-banner__input{
      flex-grow: 1;
      height: 50px;
    }
    .blog-banner__submit{
      margin: 0 0 0 8px;
      &:first-of-type {
        margin-left: 0;
      }
    } 
  }
  @media only screen and (max-width : 991px){
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 48px;
    &__img{ margin: 0 48px 0 0;}
    &__title{
      margin-bottom: 16px;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: .15px;
    }
    &__form{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__input{
      flex-grow: 1;
      height: 50px;
    }
    &__submit{
      margin: 0 0 0 8px;
      width: 146px;
    }    
  }
  @media only screen and (max-width : 767px){
    display: block;
    margin: 0 auto;
    max-width: 304px;
    padding: 48px 24px;
    &__img{ margin: 0 0 32px 0;}
    &__title{
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: .1px;
    }
    &__form{display: block;}
    &__submit{
      margin: 16px 0 0 0;
      width: 100%;
    }  
    &--row{
      display: block;
      margin: 0 auto;
      max-width: 304px;
      padding: 48px 24px;
      .blog-banner__img{ margin: 0 0 32px 0;}
      .blog-banner__title{
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: .1px;
      }
      .blog-banner__form{display: block;}
      .blog-banner__submit{
        margin: 16px 0 0 0;
        width: 100%;
      } 
    }
  }
}

.blog-item{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
  &__content {
    overflow: hidden;
  }
  &:last-child{margin-bottom: 0;}
  &__category{
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .75px;
    color: #7D8B92;
  }
  &__title{
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: .15px;
    color: #000;
  }
  &__txt{
    margin-top: 4px;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .4px;
    color: #96A1A7;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__pic{
    background: 0 0 no-repeat #DDE1E2;
    background-size: cover;
    flex-shrink: 0;
  }
  &--main{
    display: block;
    .blog-item__pic{
      width: 100%;
      padding-top: 42.67%;
      margin-bottom: 16px;
    }
  }
  &--simple{
    .blog-item__pic{
      width: 132px;
      padding-top: 132px;
      margin-right: 20px;
    }
  }
  &--long{
    .blog-item__pic{
      width: 176px;
      padding-top: 132px;
      margin-right: 16px;
    }
  }
  @media only screen and (max-width : 767px){
    &--main{
      .blog-item__pic{padding-top: 38.28%;}
    }
    &--simple{
      .blog-item__pic{
        width: 84px;
        padding-top: 84px;
        margin-right: 16px;
      }
    }
    &--long{
      .blog-item__pic{
        width: 128px;
        padding-top: 128px;
      }
    }
  }
  @media only screen and (max-width : 375px){
    &--long{
      .blog-item__pic{
        width: 104px;
        padding-top: 104px;
      }
    }
  }
}