.audience {
  position: relative;
  background: linear-gradient(270deg, rgba(10, 12, 14, 0) 39.84%, rgba(10, 12, 14, 0.2625) 70.57%), url(../img/pic/audience.png) center center no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  min-height: 700px;
  display: flex;
  align-items: center;
  margin-bottom: 180px;

  &__content {
    color: white;
    max-width: 552px;
  }

  &__title {
    font-size: 40px;
    line-height: 56px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 18px;
    line-height: 28px;
    opacity: 0.85;
    width: 450px;
    margin-bottom: 15px;
  }

  @media only screen and (max-width: 1365px) {
    background: linear-gradient(270deg, rgba(10, 12, 14, 0) 39.84%, rgba(10, 12, 14, 0.2625) 70.57%), url(../img/pic/audience.png) center center no-repeat;
    background-size: cover;
    height: 700px;

    .image-container {
      margin: 0 125px;
    }

    &__title {
      font-size: 45px;
      line-height: 56px;
    }

    &__description {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 1024px) {
    background: linear-gradient(270deg, rgba(10, 12, 14, 0) -8.46%, rgba(10, 12, 14, 0.525) 70.57%), url(../img/pic/audience.png) center center no-repeat;
    background-size: cover;
    background-position: left center;
    height: 500px;
    min-height: 500px;

    .image-container {
      margin: 0 10px;
    }

    &__title {
      font-size: 45px;
      line-height: 56px;
      font-weight: 800;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    background: linear-gradient(270deg, rgba(10, 12, 14, 0) -8.46%, rgba(10, 12, 14, 0.525) 70.57%), url(../img/pic/audience.png) center center no-repeat;
    background-size: cover;
    height: 500px;
    min-height: 500px;
    margin-bottom: 120px;

    .image-container {
      margin: 0 10px;
    }

    &__title {
      font-size: 45px;
      line-height: 56px;
      font-weight: 800;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      max-width: 90%;
    }
  }

  @media only screen and (max-width: 414px) {
    background: linear-gradient(270deg, rgba(10, 12, 14, 0.7), rgba(10, 12, 14, 0.7)), url(../img/pic/audience.png) center center no-repeat;
    background-size: cover;
    height: 500px;
    min-height: 500px;

    .image-container {
      margin: 0 10px;
    }


    &__title {
      font-size: 25px;
      line-height: 32px;
      font-weight: 800;
    }

    &__description {
      font-size: 16px;
      line-height: 22px;
      max-width: 90%;
    }
  }

  @media only screen and (max-width: 320px) {
    background: linear-gradient(270deg, rgba(10, 12, 14, 0.7), rgba(10, 12, 14, 0.7)), url(../img/pic/audience.png) center center no-repeat;
    background-size: cover;
    height: 500px;
    min-height: 500px;

    .image-container {
      margin: 0 10px;
    }

    &__title {
      font-size: 25px;
      line-height: 32px;
      font-weight: 800;
    }

    &__description {
      font-size: 16px;
      line-height: 22px;
      max-width: 290px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .image-container {
    margin: 0 100px;
  }
}

@media only screen and (max-width: 768px) {
  .image-container {
    margin: 0 20px;
  }
}
